import { IMultiSelectChipFilterOption, MainColors } from '@guider-global/ui';

export interface StatusConfig {
  id: string;
  label: string;
  chipColor: MainColors;
}
export type GroupStatusId = 'concluded' | 'pending' | 'active';

export function getGroupStatusOptions(
  guideLabel: string,
): IMultiSelectChipFilterOption[] {
  return [
    {
      id: 'concluded',
      label: `Concluded by ${guideLabel}`,
      color: 'warning',
    },
    {
      id: 'pending',
      label: `Pending Activation`,
      color: 'info',
    },
    {
      id: 'active',
      label: `activated`,
      color: 'success',
    },
  ];
}

export function parseGroupStatus({
  guideLabel,
  id,
}: {
  guideLabel: string;
  id: GroupStatusId;
}) {
  const statuses = getGroupStatusOptions(guideLabel);

  return statuses.find((status) => status.id === id);
}
