import { IChartsProgramTraineeProfile } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IUseAdminChartsProgramTraineeProfilesParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsProgramTraineeProfile,
      IChartsProgramTraineeProfile,
      IProgramChartParams
    >,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsProgramTraineeProfiles({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsProgramTraineeProfilesParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsProgramTraineeProfile,
      IChartsProgramTraineeProfile,
      IProgramChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'program-trainee-profiles',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsProgramTraineeProfiles: data,
    reqAdminChartsProgramTraineeProfiles: request,
    revalidateAdminChartsProgramTraineeProfiles: revalidate,
    errorAdminChartsProgramTraineeProfiles: error,
    isLoadingAdminChartsProgramTraineeProfiles: isLoading,
    isValidatingAdminChartsProgramTraineeProfiles: isValidating,
    isErrorAdminChartsProgramTraineeProfiles: Boolean(error),
  };
}
