import { useSettings } from '@guider-global/front-end-hooks';
import { useSanityLocalization } from '@guider-global/sanity-hooks';
import { SessionStorageKeys, useProfiles } from 'hooks';

import { useCallback } from 'react';
import { RootState } from 'store';

interface UseLocalizationResults {
  localeCode?: string;
  handleLanguageChange: (localeCode: string) => Promise<void>;
}

export const useLocalization = (
  organizationSlug: string,
): UseLocalizationResults => {
  // - Profile
  const { profiles: getProfiles } = useProfiles({});
  const profiles = getProfiles();
  const profile = profiles?.[0];
  const profileId = profile?.id;
  // - Setting
  const { isLoadingSettings, settings, reqSettings } = useSettings({});

  const userSetting = settings?.[0];
  const userSettingsId = userSetting?.id;

  const lastUsedLanguage = userSetting?.localeCode;

  const onLanguageChange = useCallback(
    async (localeCode: string) => {
      if (userSettingsId) {
        await reqSettings({
          method: 'PATCH',
          url: `/settings/${userSettingsId}`,
          data: {
            localeCode,
          },
        });
      } else {
        await reqSettings({
          method: 'POST',
          url: `/settings`,
          data: {
            localeCode,
            profile: profileId,
          },
        });
      }
    },
    [profileId, reqSettings, userSettingsId],
  );

  const { localeCode, handleLanguageChange } = useSanityLocalization<RootState>(
    organizationSlug,
    SessionStorageKeys,
    onLanguageChange,
    isLoadingSettings,
    lastUsedLanguage,
  );

  return {
    localeCode,
    handleLanguageChange,
  };
};
