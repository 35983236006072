import { IChartsProgramGuideProfile } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IUseAdminChartsProgramGuideProfilesParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsProgramGuideProfile,
      IChartsProgramGuideProfile,
      IProgramChartParams
    >,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsProgramGuideProfiles({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsProgramGuideProfilesParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsProgramGuideProfile,
      IChartsProgramGuideProfile,
      IProgramChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'program-guide-profiles',
        ...params,
      },
      options: { keepPreviousData: true, ...options },
      mutateOptions,
    });
  return {
    adminChartsProgramGuideProfiles: data,
    reqAdminChartsProgramGuideProfiles: request,
    revalidateAdminChartsProgramGuideProfiles: revalidate,
    errorAdminChartsProgramGuideProfiles: error,
    isLoadingAdminChartsProgramGuideProfiles: isLoading,
    isValidatingAdminChartsProgramGuideProfiles: isValidating,
    isErrorAdminChartsProgramGuideProfiles: Boolean(error),
  };
}
