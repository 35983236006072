import { getCountryCodeName } from '@guider-global/front-end-utils';
import { IChartsGroupRelationshipsFactoryResponse } from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  Chip,
  CustomAutocompleteFilter,
  getCustomGridAutocompleteFilterOperator,
  getCustomGridDateFilterOperator,
} from '@guider-global/ui';
import { GridCellParams, GridColDef, GridFilterItem } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { getGroupStatusOptions, parseGroupStatus } from '../parseGroupStatus';

export interface IGetGroupColumnParams {
  guideLabel: string;
  traineeLabel: string;
  countryLabel?: string;
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
}
type GroupGridColumn<Value = any, FormattedValue = Value> = GridColDef<
  IChartsGroupRelationshipsFactoryResponse,
  Value,
  FormattedValue
>;

export const getGroupColumns = ({
  guideLabel = 'Guide',
  traineeLabel = 'Trainee',
  countryLabel = 'Country',
  countryOptions,
  countryEnabled,
}: IGetGroupColumnParams) => {
  const getCountryColumn = () => {
    if (countryEnabled) {
      const countryColumn: GroupGridColumn<string, string> = {
        field: 'guideCountry',
        headerName: countryLabel,
        valueFormatter: ({ value }) => getCountryCodeName(value) ?? value,
        minWidth: 200,
        filterOperators: [
          getCustomGridAutocompleteFilterOperator({
            componentProps: {
              options: countryOptions,
            },
          }),
        ],
        type: 'string',
      };
      return [countryColumn];
    }
    return [];
  };
  const columns: GroupGridColumn[] = [
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 2,
      valueGetter: ({ row }) => {
        const isActive = Boolean(row.activatedAt);
        const isConcluded = row.isConcluded;
        if (isConcluded) return 'concluded';
        if (isActive) return 'active';
        return 'pending';
      },
      valueFormatter: ({ value }) => {
        const status = parseGroupStatus({
          guideLabel: guideLabel,
          id: value,
        });
        return status?.label;
      },
      renderCell: ({ value }) => {
        const status = parseGroupStatus({
          guideLabel: guideLabel,
          id: value,
        });
        return <Chip {...status} label={`${status?.label}`} size="small" />;
      },
      filterOperators: [
        {
          label: 'select',
          value: 'includes',
          getApplyFilterFn: (
            filterItem: GridFilterItem,
            column: GridColDef,
          ) => {
            if (
              !filterItem.field ||
              !filterItem.value ||
              !filterItem.operator ||
              !Array.isArray(filterItem.value)
            ) {
              return null;
            }
            return (params: GridCellParams): boolean => {
              return filterItem.value.includes(params.value);
            };
          },
          InputComponent: CustomAutocompleteFilter,
          InputComponentProps: {
            options: getGroupStatusOptions(guideLabel),
            label: 'Status Tags',
          },
          getValueAsString: (value) => {
            if (Array.isArray(value)) {
              return value.map((item) => item.id);
            }
            return value?.id;
          },
        },
      ],
    },
    {
      field: 'title',
      headerName: 'Group name',
      minWidth: 100,
      flex: 1.5,
    },
    {
      field: 'guideFirstName',
      headerName: `${guideLabel} first name`,
      minWidth: 100,
      flex: 0.75,
    },
    {
      field: 'guideLastName',
      headerName: `${guideLabel} last name`,
      minWidth: 100,
      flex: 0.75,
    },
    {
      field: 'guideEmail',
      headerName: `${guideLabel} email`,
      minWidth: 100,
      flex: 1,
    },
    ...getCountryColumn(),
    {
      field: 'averageSessionAttendanceRate',
      headerName: 'Average Session Attendance Rate',
      type: 'number',
      valueFormatter: ({ value }) => `${value}%`,
      minWidth: 150,
    },
    {
      field: 'trainees',
      headerName: `${traineeLabel} Count`,
      valueGetter: ({ row }) => row.traineeProfiles?.length ?? 0,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      type: 'date',
      minWidth: 100,
      flex: 0.75,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    {
      field: 'activatedAt',
      headerName: 'Activated On',
      type: 'date',
      minWidth: 100,
      flex: 0.75,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
    },
    {
      field: 'concludedAt',
      headerName: 'Concluded On',
      type: 'date',
      minWidth: 100,
      flex: 0.75,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
    },
  ];
  return columns;
};
